import React, { useState } from 'react';
import axios from 'axios';
import config from '../configuration/config'; // Adjust the path as needed

const SocialForm = () => {
    const [formData, setFormData] = useState({
        linkedin: '',
        instagram: '',
        address: '',
        number: '',
        email: ''
    });

    const [successMessage, setSuccessMessage] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${config.API_BASE_URL}/social`, formData);
            console.log('Data saved:', response.data);
            setSuccessMessage(true); // Show the success message
            setTimeout(() => setSuccessMessage(false), 3000); // Hide the message after 3 seconds
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    return (
        <div>
            {/* Success Message Dialog */}
            {successMessage && (
                <div className="success-dialog">
                    <p>Information updated successfully!</p>
                </div>
            )}

            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-xxl-6 col-md-6">
                        <div className="profile__input-box">
                            <div className="profile__input">
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                                <span>
                                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13 14.6H5C2.6 14.6 1 13.4 1 10.6V5C1 2.2 2.6 1 5 1H13C15.4 1 17 2.2 17 5V10.6C17 13.4 15.4 14.6 13 14.6Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeMiterlimit="10"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M13 5.3999L10.496 7.3999C9.672 8.0559 8.32 8.0559 7.496 7.3999L5 5.3999"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeMiterlimit="10"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-6 col-md-6">
                        <div className="profile__input-box">
                            <div className="profile__input">
                                <input
                                    type="text"
                                    name="linkedin"
                                    value={formData.linkedin}
                                    onChange={handleChange}
                                    required
                                />
                                <span>
                                    <i className="fa-brands fa-linkedin-in"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-6 col-md-6">
                        <div className="profile__input-box">
                            <div className="profile__input">
                                <input
                                    type="text"
                                    name="instagram"
                                    value={formData.instagram}
                                    onChange={handleChange}
                                    required
                                />
                                <span>
                                    <i className="fa-brands fa-instagram"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-6 col-md-6">
                        <div className="profile__input-box">
                            <div className="profile__input">
                                <input
                                    type="text"
                                    name="number"
                                    value={formData.number}
                                    onChange={handleChange}
                                    required
                                />
                                <span>
                                    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13.9148 5V13C13.9148 16.2 13.1076 17 9.87892 17H5.03587C1.80717 17 1 16.2 1 13V5C1 1.8 1.80717 1 5.03587 1H9.87892C13.1076 1 13.9148 1.8 13.9148 5Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            opacity="0.4"
                                            d="M9.08026 3.80054H5.85156"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            opacity="0.4"
                                            d="M7.45425 14.6795C8.14522 14.6795 8.70537 14.1243 8.70537 13.4395C8.70537 12.7546 8.14522 12.1995 7.45425 12.1995C6.76327 12.1995 6.20312 12.7546 6.20312 13.4395C6.20312 14.1243 6.76327 14.6795 7.45425 14.6795Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-12">
                        <div className="profile__input-box">
                            <div className="profile__input">
                                <input
                                    type="text"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    required
                                />
                                <span>
                                    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M7.99377 10.1461C9.39262 10.1461 10.5266 9.0283 10.5266 7.64946C10.5266 6.27061 9.39262 5.15283 7.99377 5.15283C6.59493 5.15283 5.46094 6.27061 5.46094 7.64946C5.46094 9.0283 6.59493 10.1461 7.99377 10.1461Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                        />
                                        <path
                                            d="M1.19707 6.1933C2.79633 -0.736432 13.2118 -0.72843 14.803 6.2013C15.7365 10.2663 13.1712 13.7072 10.9225 15.8357C9.29079 17.3881 6.70924 17.3881 5.06939 15.8357C2.8288 13.7072 0.263493 10.2583 1.19707 6.1933Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-12">
                        <div className="profile__btn">
                            <button type="submit" className="tp-btn-cart sm">
                                Update Info
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default SocialForm;
