import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../configuration/config'; // Adjust the path as needed

const ProjectListEdit = () => {
    const [projects, setProjects] = useState([]);
    const [editProjectId, setEditProjectId] = useState(null);
    const [editTitle, setEditTitle] = useState('');
    const [editCategory, setEditCategory] = useState('');

    useEffect(() => {
        // Fetch projects data from the backend
        const fetchProjects = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/projects`);
                setProjects(response.data);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        fetchProjects();
    }, []);

    // Function to handle deleting a project
    const handleDeleteProject = async (projectId) => {
        try {
            await axios.delete(`${config.API_BASE_URL}/projects/${projectId}`);
            setProjects(projects.filter(project => project.id !== projectId));
        } catch (error) {
            console.error('Error deleting project:', error);
        }
    };

    // Function to handle editing a project's details
    const handleEditProject = (project) => {
        setEditProjectId(project.id);
        setEditTitle(project.title);
        setEditCategory(project.category);
    };

    // Function to handle saving updated project details
    const handleSaveProject = async (projectId) => {
        try {
            await axios.put(`${config.API_BASE_URL}/projects/${projectId}`, {
                title: editTitle,
                category: editCategory,
            });

            setProjects(projects.map(project =>
                project.id === projectId ? { ...project, title: editTitle, category: editCategory } : project
            ));
            setEditProjectId(null);
        } catch (error) {
            console.error('Error saving project:', error);
        }
    };

    // Function to handle updating a project's image
    const handleUpdateProjectImage = async (projectId, imageIndex, file) => {
        const formData = new FormData();
        formData.append('image', file);
        formData.append('imageIndex', imageIndex);

        try {
            const response = await axios.put(`${config.API_BASE_URL}/projects/${projectId}/update-image`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // Update the project image in the state
            setProjects(projects.map(project =>
                project.id === projectId ? { ...project, [`image${imageIndex}`]: response.data.image } : project
            ));
        } catch (error) {
            console.error('Error updating project image:', error);
        }
    };

    return (
        <div>
            {projects.map((project) => (
                <div key={project.id} style={{ marginBottom: '20px' }}>
                    {/* Row for Images */}
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <div style={{ flex: 1, marginRight: '10px' }}>
                            <img
                                src={`${config.API_BASE_URL_IMAGE}${project.image1}`}
                                alt="Project Image 1"
                                style={{ width: '100px', height: 'auto' }}
                            />
                            <input
                                type="file"
                                onChange={(e) => handleUpdateProjectImage(project.id, 1, e.target.files[0])}
                                style={{ marginTop: '5px' }}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <img
                                src={`${config.API_BASE_URL_IMAGE}${project.image2}`}
                                alt="Project Image 2"
                                style={{ width: '100px', height: 'auto' }}
                            />
                            <input
                                type="file"
                                onChange={(e) => handleUpdateProjectImage(project.id, 2, e.target.files[0])}
                                style={{ marginTop: '5px' }}
                            />
                        </div>
                    </div>

                    {/* Row for Inputs and Buttons */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 3 }}>
                            {editProjectId === project.id ? (
                                <>
                                    <input
                                        type="text"
                                        value={editTitle}
                                        onChange={(e) => setEditTitle(e.target.value)}
                                        placeholder="Edit Title"
                                        style={{
                                            marginBottom: '10px',
                                            display: 'block',
                                            width: '100%',
                                            padding: '5px'
                                        }}
                                    />
                                    <input
                                        type="text"
                                        value={editCategory}
                                        onChange={(e) => setEditCategory(e.target.value)}
                                        placeholder="Edit Category"
                                        style={{
                                            marginBottom: '10px',
                                            display: 'block',
                                            width: '100%',
                                            padding: '5px'
                                        }}
                                    />
                                    <button
                                        onClick={() => handleSaveProject(project.id)}
                                        style={{
                                            padding: '5px 10px',
                                            marginRight: '10px',
                                            backgroundColor: 'blue',
                                            color: 'white',
                                            border: 'none',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Save
                                    </button>
                                </>
                            ) : (
                                <>
                                    <div>{project.title}</div>
                                    <div>{project.category}</div>
                                    <button
                                        onClick={() => handleEditProject(project)}
                                        style={{
                                            marginTop: '10px',
                                            padding: '5px 10px',
                                            backgroundColor: 'gray',
                                            color: 'white',
                                            border: 'none',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Edit
                                    </button>
                                </>
                            )}
                        </div>
                        <div style={{ flex: 1, textAlign: 'right' }}>
                            <button
                                onClick={() => handleDeleteProject(project.id)}
                                style={{
                                    marginLeft: '10px',
                                    padding: '5px 10px',
                                    backgroundColor: 'red',
                                    color: 'white',
                                    border: 'none',
                                    cursor: 'pointer'
                                }}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProjectListEdit;
