import React, { useState } from 'react';
import axios from 'axios';
import config from '../configuration/config';

const ServicesForm = () => {
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [description, setDescription] = useState('');
    const [features, setFeatures] = useState('');
    const [image, setImage] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (!title || !subtitle || !description || !features || !image) {
            setError('All fields are required.');
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('subtitle', subtitle);
        formData.append('description', description);
        formData.append('features', features); // Assuming features is a comma-separated string
        formData.append('image', image);

        try {
            const response = await axios.post(`${config.API_BASE_URL}/services`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setSuccess('Service added successfully.');
            setTitle('');
            setSubtitle('');
            setDescription('');
            setFeatures('');
            setImage(null);
        } catch (error) {
            setError('There was an error submitting the form.');
            console.error('Error submitting form:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="service-form">
            {error && <div className="error-message">{error}</div>}
            {success && <div className="success-message">{success}</div>}

            <div className="cn-contactform-input mb-25">
                <label>Title:</label>
                <input 
                    type="text" 
                    value={title} 
                    onChange={(e) => setTitle(e.target.value)} 
                    required 
                />
            </div>

            <div className="cn-contactform-input mb-25">
                <label>Subtitle:</label>
                <input 
                    type="text" 
                    value={subtitle} 
                    onChange={(e) => setSubtitle(e.target.value)} 
                    required 
                />
            </div>

            <div className="cn-contactform-input mb-25">
                <label>Description:</label>
                <textarea 
                    value={description} 
                    onChange={(e) => setDescription(e.target.value)} 
                    required 
                />
            </div>

            <div className="cn-contactform-input mb-25">
                <label>Features (comma-separated):</label>
                <input 
                    type="text" 
                    value={features} 
                    onChange={(e) => setFeatures(e.target.value)} 
                    required 
                />
            </div>

            <div className="cn-contactform-input mb-25">
                <label>Image:</label>
                <input 
                    type="file" 
                    accept="image/*" 
                    onChange={handleImageChange} 
                    required 
                />
            </div>

            <div className="profile__main-logout text-sm-end">
                <button className="tp-logout-btn" type="submit">Submit</button>
            </div>
        </form>
    );
};

export default ServicesForm;
