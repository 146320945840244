import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation'; // If you're using navigation module
import 'swiper/css/pagination'; // If you're using pagination module


import HomePage from './pages/HomePage';
import AboutUs from './pages/AboutUs';
import Services from './pages/Services';
import BrandForm from './pages/BrandForm';
import Submissions from './pages/Submissions';
import ContactPage from './pages/ContactPage';

function App() {
    useEffect(() => {
        const loadScript = (src) => {
            if (document.querySelector(`script[src="${src}"]`)) {
                return Promise.resolve();
            }
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src.startsWith("http") ? src : `${process.env.PUBLIC_URL}/${src}`;
                script.async = true;
                script.onload = resolve;
                script.onerror = () => reject(new Error(`Failed to load script ${src}`));
                document.body.appendChild(script);
            });
        };

        const loadScriptsSequentially = async () => {
            try {
                await loadScript('https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js');
                await loadScript('https://cdnjs.cloudflare.com/ajax/libs/jquery.imagesloaded/4.1.4/imagesloaded.pkgd.min.js');
                await loadScript('assets/js/purecounter.js');
                await loadScript('assets/js/isotope-pkgd.js');
                await loadScript('assets/js/gsap.js');
                await loadScript('assets/js/gsap-scroll-trigger.js');
                await loadScript('assets/js/gsap-scroll-smoother.js');
                await loadScript('assets/js/gsap-scroll-to-plugin.js');
                await loadScript('assets/js/scroll-magic.js');
                //await loadScript('swiper/swiper-bundle.min.css');

                await Promise.all([
                    loadScript('assets/js/bootstrap-bundle.js'),
                    loadScript('assets/js/range-slider.js'),
                    loadScript('assets/js/slick.js'),
                    loadScript('assets/js/magnific-popup.js'),
                    loadScript('assets/js/nice-select.js'),
                    loadScript('assets/js/beforeafter.js'),
                    loadScript('assets/js/ajax-form.js'),
                    loadScript('assets/js/tp-cursor.js'),
                    loadScript('assets/js/swiper-bundle.js'),
                ]);

                // Load chroma.min.js and gsap-split-text.js before main.js
                await loadScript('assets/js/chroma.min.js');
                await loadScript('assets/js/gsap-split-text.js');
                await loadScript('assets/js/main.js');  // main.js loaded last
            } catch (error) {
                console.error('Error loading scripts:', error);
            }
        };

        loadScriptsSequentially();

        return () => {
            const scriptsToRemove = document.body.querySelectorAll('script[src*="assets/js/"]');
            scriptsToRemove.forEach(script => document.body.removeChild(script));
        };
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<><Header /><AboutUs /></>} />
                <Route path="/aboutus" element={<><Header /><HomePage /></>} />
                <Route path="/services" element={<><Header /><Services /></>} />
                <Route path="/brandform" element={<BrandForm />} />
                <Route path="/submissions" element={<Submissions />} />
                <Route path="/contactpage" element={<><Header /><ContactPage /></>} />
            </Routes>
            {/* <Footer /> */}
        </Router>
    );
}

export default App;
