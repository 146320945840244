import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './BrandList.css'; // Import the CSS file for styling
import config from '../configuration/config'; // Adjust the path as needed

const BrandList = () => {
    const [brands, setBrands] = useState([]);

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/brands`);
                setBrands(response.data);
            } catch (error) {
                console.error('Error fetching brands:', error);
            }
        };

        fetchBrands();
    }, []);

    // Flatten slides to prepare images
    const flattenSlides = () => {
        return brands.flatMap((brand) =>
            brand.images.map((img, imgIndex) => ({
                id: `${brand.id}-${imgIndex}`,
                src: `${config.API_BASE_URL_IMAGE}${img.image}`,
                alt: `Brand ${brand.name} Image ${imgIndex}`,
            }))
        );
    };

    // Duplicate images for infinite scrolling
    const slides = flattenSlides();
    const duplicatedSlides = [
        ...slides,
        ...slides.map((slide, index) => ({ ...slide, id: `${slide.id}-dup-${index}` })),
    ];

    return (
        <div id="container">
            <div className="photobanner">
                {duplicatedSlides.map((slide) => (
                    <img
                        key={slide.id} // Ensured unique keys
                        src={slide.src}
                        alt={slide.alt}
                        className="brand-image" // Ensure correct usage
                    />
                ))}
            </div>
        </div>
    );
};

export default BrandList;
