import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Footer from '../components/Footer';
import config from '../configuration/config'; // Adjust the path as needed
import ContactForm from './ContactForm';

const ContactPage = () => {
    const [data, setData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/social`);
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    return (
        <div id="smooth-wrapper">
            <div id="smooth-content">

                <div class="inner-bg" data-background="assets/img/home-01/team/team-details-bg.png">

                    <main>

                        <div class="tm-hero-area tm-hero-ptb p-relative">
                            <div class="container">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="tm-hero-content">
                                            <span class="tm-hero-subtitle">TBB</span>
                                            <h4 class="tm-hero-title-big tp-char-animation">Get <br/> in touch</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="cn-contactform-area cn-contactform-style p-relative pb-100">
                            <div class="container container-1840">
                                <div class="cn-contactform-2-bg black-bg">
                                    <div class="row">
                                        <div class="col-xl-6">
                                            <div class="cn-contactform-2-map">
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d37866.42024631252!2d90.04636289871837!3d23.823039160865342!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1699251079678!5m2!1sen!2sbd" title ="map"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            </div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="cn-contactform-wrap">
                                                <h4 class="cn-contactform-2-title">Send a Message</h4>
                                                <ContactForm />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="cn-contactform-support-area mb-120">
                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-xl-10">
                                        <div class="cn-contactform-support-bg d-flex align-items-center justify-content-center" data-background="assets/img/inner-contact/contact/contact-bg.png">
                                            <div class="cn-contactform-support-text text-center">
                                                <span>Or, you can contact one of our studios
                                                    directly below. We aim to respond
                                                    within 24 hours.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="cn-contact-2-info-area pb-90">
                            <div class="container container-1530">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-6 mb-30">

                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
                                            <div class="cn-contact-2-content mt-60 text-center">
                                                <h4 class="cn-contact-2-title">Hyderabad</h4>
                                                <div class="cn-contact-2-thumb d-flex justify-content-center">
                                                        </div>
                                                        <div class="cn-contact-2-info-details">
                                                            <a class="pb-15" href="https://www.google.com/maps/@23.822356,90.3671947,15z?entry=ttu" target="_blank">
                                                               {data.address}
                                                            </a>
                                                            <a href={`mailto:${data.email}`}>{data.email}</a>
                                                            <a href={`tel:+91${data.number}`}>+91 {data.number}</a>
                                                        </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-6 mb-30">

                                                </div>
                                            </div>
                                        </div>
                        </div>


                    </main>

                     <Footer />

                </div>
            </div>
        </div>
         
    );
};

export default ContactPage;
