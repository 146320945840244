import React, { useState } from 'react';
import axios from 'axios';
import config from '../configuration/config'; // Adjust the path as needed

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        subject: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(' ${ config.API_BASE_URL }/send-email', formData);
            alert(response.data.message);
        } catch (error) {
            alert('Failed to send email. Please try again.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="cn-contactform-input mb-25">
                <label>Name</label>
                <input type="text" name="name" placeholder="John Doe" value={formData.name} onChange={handleChange} />
            </div>
            <div className="cn-contactform-input mb-25">
                <label>Email</label>
                <input type="email" name="subject" placeholder="Your@email.com" value={formData.subject} onChange={handleChange} />
            </div>
            <div className="cn-contactform-input mb-25">
                <label>Message</label>
                <textarea name="message" placeholder="Tell Us About Your Project" value={formData.message} onChange={handleChange}></textarea>
            </div>
            <div className="cn-contactform-btn">
                <button className="tp-btn-black-md white-bg w-100" type="submit">Send Message</button>
            </div>
        </form>
    );
};

export default ContactForm;


           