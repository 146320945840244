import React, { useState } from 'react';
import Authentication from './Authentication';
import BrandForm from './BrandForm';
import ProjectForm from './ProjectForm';
import SocialForm from './SocialForm';
import BrandListEdit from './BrandListEdit';
import ProjectListEdit from './ProjectListEdit';
import ServicesForm from './ServicesForm';
import ServicesEdit from './ServicesEdit';
import BrandFormForAboutUs from './BrandFormForAboutUs';
import BrandFormForAboutUsEdit from './BrandFormForAboutUsEdit';

const Submissions = () => {
        const [isAuthenticated, setIsAuthenticated] = useState(false);

        const handleAuthenticationSuccess = () => {
            setIsAuthenticated(true);
        };

        return (
            <div>
                {/*{!isAuthenticated ? (*/}
                {/*    <Authentication onSuccess={handleAuthenticationSuccess} />*/}
                {/*) : (*/}
                    <>
                            <div id="smooth-wrapper">
                                <div id="smooth-content">

                                    <main>
                                        <section class="profile__area pt-200 pb-120">
                                            <div class="container">
                                                <div class="profile__inner p-relative">
                                                    <div class="row">
                                                        <div class="col-xxl-4 col-lg-4">
                                                            <div class="profile__tab mr-40">
                                                                <nav>
                                                                    <div class="nav nav-tabs tp-tab-menu flex-column" id="profile-tab" role="tablist">
                                                                        <button class="nav-link active" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false"><span><i class="fa-regular fa-user-pen"></i></span>Data</button>
                                                                        <button class="nav-link" id="nav-information-tab" data-bs-toggle="tab" data-bs-target="#nav-information" type="button" role="tab" aria-controls="nav-information" aria-selected="false"><span><i class="fa-regular fa-circle-info"></i></span> Information</button>
                                                                        <button class="nav-link" id="nav-password-tab" data-bs-toggle="tab" data-bs-target="#nav-password" type="button" role="tab" aria-controls="nav-password" aria-selected="false"><span><i class="fa-regular fa-lock"></i></span> Change Pin</button>
                                                                    </div>
                                                                </nav>
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-8 col-lg-8">
                                                            <div class="profile__tab-content">
                                                                <div class="tab-content" id="profile-tabContent">
                                                                    <div class="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                                                        <div class="profile__main">
                                                                            <div class="profile__main-top pb-80">
                                                                                <div class="row align-items-center">
                                                                            <div class="col-md-6">
                                                                                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                                                    <li class="nav-item" role="presentation">
                                                                                        <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Brands</button>
                                                                                    </li>
                                                                                    <li class="nav-item" role="presentation">
                                                                                        <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Projects</button>
                                                                                    </li>
                                                                                    <li class="nav-item" role="presentation">
                                                                                        <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Services</button>
                                                                                    </li>
                                                                                </ul>
                                                                                <div class="tab-content" id="pills-tabContent">
                                                                                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                                                        <br /><br /><br /><br /><br />
                                                                                        <BrandForm />
                                                                                        <br /><br /><br /><br /><br />
                                                                                        <BrandListEdit />
                                                                                        <br /><br /><br /><br /><br />
                                                                                        <BrandFormForAboutUs />
                                                                                        <br /><br /><br /><br /><br />
                                                                                        <BrandFormForAboutUsEdit />

                                                                                    </div>
                                                                                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                                                        <br /><br /><br />

                                                                                        <ProjectForm />

                                                                                        <br /><br /><br />
                                                                                        <ProjectListEdit />
                                                                                    </div>
                                                                                    <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                                                                    <br /><br /><br />

                                                                                     <ServicesForm />

                                                                                     <br /><br /><br />
                                                                                     <ServicesEdit />
                                                                                        
                                                                                    </div>
                                                                                </div>

                                                                                    
                                                                                        
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                           
                                                                        </div>
                                                                    </div>
                                                                    <div class="tab-pane fade" id="nav-information" role="tabpanel" aria-labelledby="nav-information-tab">
                                                                        <div class="profile__info">
                                                                            <h3 class="profile__info-title">Personal Details</h3>
                                                                    <div class="profile__info-content">
                                                                        <SocialForm/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="tab-pane fade" id="nav-password" role="tabpanel" aria-labelledby="nav-password-tab">
                                                                        <div class="profile__password">
                                                                            <form action="#">
                                                                                <div class="row">
                                                                                    <div class="col-xxl-12">
                                                                                        <div class="tp-profile-input-box">
                                                                                            <div class="tp-profile-input-title">
                                                                                                <label for="old_pass">Old Password</label>
                                                                                            </div>
                                                                                            <div class="tp-contact-input">
                                                                                                <input name="old_pass" id="old_pass" type="password"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-xxl-6 col-md-6">
                                                                                        <div class="tp-profile-input-box">
                                                                                            <div class="tp-profile-input-title">
                                                                                                <label for="new_pass">New Password</label>
                                                                                            </div>
                                                                                            <div class="tp-profile-input">
                                                                                                <input name="new_pass" id="new_pass" type="password"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-xxl-6 col-md-6">
                                                                                        <div class="tp-profile-input-box">
                                                                                            <div class="tp-profile-input-title">
                                                                                                <label for="con_new_pass">Confirm Password</label>
                                                                                            </div>
                                                                                            <div class="tp-profile-input">
                                                                                                <input name="con_new_pass" id="con_new_pass" type="password"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-xxl-6 col-md-6">
                                                                                        <div class="profile__btn">
                                                                                            <button type="submit" class="tp-btn-cart sm">Update</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                       
                                    </main>

                                </div>
                            </div>
                        
                        
                    </>
                {/*)}*/}
            </div>
        );
    };

export default Submissions;