import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../configuration/config'; // Adjust the path as needed

const ServicesEdit = () => {
    const [services, setServices] = useState([]);
    const [editServiceId, setEditServiceId] = useState(null);
    const [editTitle, setEditTitle] = useState('');
    const [editSubtitle, setEditSubtitle] = useState('');
    const [editDescription, setEditDescription] = useState('');
    const [editFeatures, setEditFeatures] = useState('');

    useEffect(() => {
        // Fetch services data from the backend
        const fetchServices = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/services`);
                setServices(response.data);
            } catch (error) {
                console.error('Error fetching services:', error);
            }
        };

        fetchServices();
    }, []);

    // Function to handle deleting a service
    const handleDeleteService = async (serviceId) => {
        try {
            await axios.delete(`${config.API_BASE_URL}/services/${serviceId}`);
            setServices(services.filter(service => service.id !== serviceId));
        } catch (error) {
            console.error('Error deleting service:', error);
        }
    };

    // Function to handle editing a service's details
    const handleEditService = (service) => {
        setEditServiceId(service.id);
        setEditTitle(service.title);
        setEditSubtitle(service.subtitle);
        setEditDescription(service.description);
        setEditFeatures(service.features ? service.features.join(', ') : ''); // Join array into comma-separated string
    };

    // Function to handle saving updated service details
    const handleSaveService = async (serviceId) => {
        try {
            await axios.put(`${config.API_BASE_URL}/services/${serviceId}`, {
                title: editTitle,
                subtitle: editSubtitle,
                description: editDescription,
                features: editFeatures.split(',').map(f => f.trim()) // Split comma-separated string into array
            });

            setServices(services.map(service =>
                service.id === serviceId
                    ? {
                        ...service,
                        title: editTitle,
                        subtitle: editSubtitle,
                        description: editDescription,
                        features: editFeatures.split(',').map(f => f.trim())
                    }
                    : service
            ));
            setEditServiceId(null);
        } catch (error) {
            console.error('Error saving service:', error);
        }
    };

    return (
        <div>
            {services.map((service) => {
                // Parse `features` safely
                const parsedFeatures = Array.isArray(service.features)
                    ? service.features
                    : typeof service.features === 'string'
                    ? (() => {
                          try {
                              return JSON.parse(service.features); // If it's a JSON string
                          } catch {
                              return service.features.split(',').map((f) => f.trim()); // If it's a comma-separated string
                          }
                      })()
                    : []; // Fallback to empty array if parsing fails
    
                return (
                    <div key={service.id} style={{ marginBottom: '20px' }}>
                        {/* Row for Image */}
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <img
                                src={`${config.API_BASE_URL_IMAGE}${service.image}`}
                                alt="Service Image"
                                style={{ width: '100px', height: 'auto', marginRight: '10px' }}
                            />
                        </div>
    
                        {/* Row for Inputs and Buttons */}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 3 }}>
                                {editServiceId === service.id ? (
                                    <>
                                        <input
                                            type="text"
                                            value={editTitle}
                                            onChange={(e) => setEditTitle(e.target.value)}
                                            placeholder="Edit Title"
                                            style={{
                                                marginBottom: '10px',
                                                display: 'block',
                                                width: '100%',
                                                padding: '5px',
                                            }}
                                        />
                                        <input
                                            type="text"
                                            value={editSubtitle}
                                            onChange={(e) => setEditSubtitle(e.target.value)}
                                            placeholder="Edit Subtitle"
                                            style={{
                                                marginBottom: '10px',
                                                display: 'block',
                                                width: '100%',
                                                padding: '5px',
                                            }}
                                        />
                                        <textarea
                                            value={editDescription}
                                            onChange={(e) => setEditDescription(e.target.value)}
                                            placeholder="Edit Description"
                                            style={{
                                                marginBottom: '10px',
                                                display: 'block',
                                                width: '100%',
                                                padding: '5px',
                                            }}
                                        />
                                        <input
                                            type="text"
                                            value={editFeatures}
                                            onChange={(e) => setEditFeatures(e.target.value)}
                                            placeholder="Edit Features (comma-separated)"
                                            style={{
                                                marginBottom: '10px',
                                                display: 'block',
                                                width: '100%',
                                                padding: '5px',
                                            }}
                                        />
                                        <button
                                            onClick={() => handleSaveService(service.id)}
                                            style={{
                                                padding: '5px 10px',
                                                marginRight: '10px',
                                                backgroundColor: 'blue',
                                                color: 'white',
                                                border: 'none',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            Save
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <div>{service.title}</div>
                                        <div>{service.subtitle}</div>
                                        <div>{service.description}</div>
                                        <ul>
                                            {parsedFeatures.map((feature, index) => (
                                                <li key={index}>{feature}</li>
                                            ))}
                                        </ul>
                                        <button
                                            onClick={() => handleEditService(service)}
                                            style={{
                                                marginTop: '10px',
                                                padding: '5px 10px',
                                                backgroundColor: 'gray',
                                                color: 'white',
                                                border: 'none',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            Edit
                                        </button>
                                    </>
                                )}
                            </div>
                            <div style={{ flex: 1, textAlign: 'right' }}>
                                <button
                                    onClick={() => handleDeleteService(service.id)}
                                    style={{
                                        marginLeft: '10px',
                                        padding: '5px 10px',
                                        backgroundColor: 'red',
                                        color: 'white',
                                        border: 'none',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
    
};

export default ServicesEdit;
