import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../configuration/config'; // Adjust the path as needed

const ProjectList = () => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        // Fetch projects data from the backend
        const fetchProjects = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/projects`);
                setProjects(response.data);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        fetchProjects();
    }, []);

    return (
        <div>
            {projects.map((project, index) => (
                <div className="tp-project-3-wrap" key={index}>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className={`tp-project-3-thumb pro-img-${index * 2 + 1}`}>
                                <img
                                    src={`${config.API_BASE_URL_IMAGE}${project.image1}`}  // Using base64 image data
                                    alt={`Project Image 1 ${index}`}
                                    style={{ width: '100%', height: 'auto', margin: '5px' }}  // Adjust styling as needed
                                />
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 order-1 order-lg-0">
                            <div className="tp-project-3-content text-center">
                                <span className="tp-project-3-meta">{/*{project.date} .*/} {project.category}</span>
                                <h4 className="tp-project-3-title-sm">
                                    <a href={`portfolio-details-${index + 1}.html`}>{project.title}</a>
                                </h4>
                                <a className="tp-btn-project-sm" href="https://www.instagram.com/_tbbagency_/" >See More</a>
                            </div>
                            <div className={`tp-project-3-border color-${index + 1} text-center`}>
                                <span></span>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 order-0 order-lg-0">
                            <div className={`tp-project-3-thumb pro-img-${index * 2 + 2}`}>
                                <img
                                    src={`${config.API_BASE_URL_IMAGE}${project.image2}`}  // Using base64 image data
                                    alt={`Project Image 2 ${index}`}
                                    style={{ width: '100%', height: 'auto', margin: '5px' }}  // Adjust styling as needed
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>



    );
};

export default ProjectList;

