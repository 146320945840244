import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import config from '../configuration/config';
import './BrandListAboutus.css'; // Import your custom styles

const BrandListAboutus = () => {
    const [brands, setBrands] = useState([]);
    const sliderRef = useRef(null);
    const lastScrollTop = useRef(0);

    useEffect(() => {
        const fetchAboutUsBrands = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/brandformforaboutus`);
                setBrands(response.data);
            } catch (error) {
                console.error('Error fetching About Us brands:', error);
            }
        };

        fetchAboutUsBrands();
    }, []);

    useEffect(() => {
        const handleScroll = (event) => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (!sliderRef.current) return;

            if (scrollTop > lastScrollTop.current) {
                // Scrolling Down - Move left
                sliderRef.current.scrollLeft += 10; // Adjust the value for scroll sensitivity
            } else {
                // Scrolling Up - Move right
                sliderRef.current.scrollLeft -= 10; // Adjust the value for scroll sensitivity
            }

            lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop; // Update last scroll position
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="tp-team-area pb-120 fix">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="tp-team-slider-wrapper no-scrollbar" ref={sliderRef}>
                            <div className="brand-slider">
                                {brands.map((brand) => (
                                    <div className="brand-slide" key={brand.id}>
                                        <div className="tp-team-item marque fix mb-30">
                                            <div className="tp-hover-btn-item">
                                                <img
                                                    src={`${config.API_BASE_URL_IMAGE}${brand.image}`}
                                                    alt={`Brand ${brand.name}`}
                                                    className="brand-image"
                                                />
                                            </div>
                                            <div className="tp-team-content">
                                                <h4 className="tp-team-title-sm">
                                                    <a href="#">{brand.name}</a>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BrandListAboutus;
