import React, { useState } from 'react';
import axios from 'axios';
import config from '../configuration/config';

const BrandFormForAboutUs = () => {
    const [name, setName] = useState('');
    const [image, setImage] = useState(null); // Single image state

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]); // Take the first image if multiple are selected
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('name', name);
        if (image) {
            formData.append('image', image); // Append image (single image field)
        }

        try {
            const response = await axios.post(`${config.API_BASE_URL}/brandformforaboutus`, formData, {
                headers: {
                    // 'Content-Type': 'multipart/form-data', // No need to set this manually
                },
            });
            alert('Brand for About Us added successfully!');
            console.log('Response:', response.data);
        } catch (error) {
            console.error('Error submitting form:', error.response ? error.response.data : error);
        }
    };

    return (
        <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="cn-contactform-input mb-25">
                <label>Name</label>
                <input
                    type="text"
                    name="name"
                    placeholder="Brand Name"
                    value={name}
                    onChange={handleNameChange}
                    required
                />
            </div>
            <div className="cn-contactform-input mb-25">
                <label>Image</label>
                <input
                    type="file"
                    name="image"
                    onChange={handleImageChange}
                    required
                />
            </div>

            <div className="profile__main-logout text-sm-end">
                <button className="tp-logout-btn" type="submit">Submit</button>
            </div>
        </form>
    );
};

export default BrandFormForAboutUs;
