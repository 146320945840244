import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../configuration/config';

const BrandFormForAboutUsEdit = () => {
    const [aboutUsBrands, setAboutUsBrands] = useState([]);

    useEffect(() => {
        const fetchAboutUsBrands = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/brandformforaboutus`);
                setAboutUsBrands(response.data);
            } catch (error) {
                console.error('Error fetching About Us brands:', error);
            }
        };

        fetchAboutUsBrands();
    }, []);

    const handleDeleteImage = async (imageName) => {
        try {
          // Find the brand that has the image with the matching filename
          const brandToDelete = aboutUsBrands.find(brand => brand.image === imageName);
          
          // Check if the brand exists and has the 'image' property
          if (brandToDelete && brandToDelete.image) {
            // Send DELETE request with the image name (instead of id)
            await axios.delete(`${config.API_BASE_URL}/images${brandToDelete.image}`);
            
            // Update state to reflect the deletion of the image
            setAboutUsBrands((prevBrands) =>
              prevBrands.filter((brand) => brand.image !== brandToDelete.image)
            );
          } else {
            console.error('Image not found:', imageName);
          }
        } catch (error) {
          console.error('Error deleting image:', error);
        }
      };
      
      
      
    

    const handleUpdateImage = async (imageId, newImage) => {
        const formData = new FormData();
        formData.append('image', newImage);

        try {
            // Update image
            await axios.post(`${config.API_BASE_URL}/images/${imageId}`, formData);
            
            // After updating, instead of re-fetching all brands, update the specific brand
            setAboutUsBrands((prevBrands) =>
                prevBrands.map((brand) => ({
                    ...brand,
                    image: brand.image.id === imageId ? newImage.name : brand.image, // Update the image source
                }))
            );
        } catch (error) {
            console.error('Error updating image:', error);
        }
    };



    return (
        <div className="col-md-12">
            {aboutUsBrands.map((brand) => (
                <div key={brand.id} className="swiper-slide">
                    <div className="tp-brand-item">
                        <img
                            src={`${config.API_BASE_URL_IMAGE}${brand.image}`} // Use the image URL directly
                            alt={`About Us Brand ${brand.name} Image`}
                            style={{ width: '100px', height: '100px', margin: '5px' }}
                        />
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                        <button
  style={{ display: 'block', marginBottom: '5px' }}
  onClick={() => handleDeleteImage(brand.image)}  // Pass the image filename for deletion
>
  Delete
</button>
                            <input
                                type="file"
                                onChange={(e) => handleUpdateImage(brand.image, e.target.files[0])}  // Pass the image URL
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
    
};

export default BrandFormForAboutUsEdit;
