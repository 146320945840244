import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../configuration/config';

const BrandListEdit = () => {
    const [brands, setBrands] = useState([]);

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/brands`);
                setBrands(response.data);
            } catch (error) {
                console.error('Error fetching brands:', error);
            }
        };

        fetchBrands();
    }, []);

    const handleDeleteImage = async (imageId) => {
        try {
            await axios.delete(`${config.API_BASE_URL}/images/${imageId}`);
            setBrands((prevBrands) =>
                prevBrands.map((brand) => ({
                    ...brand,
                    images: brand.images.filter((img) => img.id !== imageId)
                }))
            );
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const handleUpdateImage = async (imageId, newImage) => {
        const formData = new FormData();
        formData.append('image', newImage);

        try {
            await axios.post(`${config.API_BASE_URL}/images/${imageId}`, formData);
            const response = await axios.get(`${config.API_BASE_URL}/brands`);
            setBrands(response.data); // Refresh brand list with updated images
        } catch (error) {
            console.error('Error updating image:', error);
        }
    };

    return (

        <div className="col-md-12">
            {brands.map((brand) =>
                brand.images.map((img, imgIndex) => (
                    <div className="swiper-slide" key={`${brand.id}-${imgIndex}`}>
                        <div className="tp-brand-item">
                            <img
                                src={`${config.API_BASE_URL_IMAGE}${img.image}`}  // Use filename directly
                                alt={`Brand ${brand.name} Image ${imgIndex}`}
                                style={{ width: '100px', height: '100px', margin: '5px' }}
                            />
                            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                <button
                                    style={{ display: 'block', marginBottom: '5px' }}
                                    onClick={() => handleDeleteImage(img.id)}
                                >
                                    Delete
                                </button>
                                <input
                                    type="file"
                                    onChange={(e) => handleUpdateImage(img.id, e.target.files[0])}
                                />
                            </div>
                        </div>
                    </div>
                ))
            )}
        </div>


    );
};

export default BrandListEdit;
