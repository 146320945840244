import React, { useState } from 'react';
import axios from 'axios';
import config from '../configuration/config'; // Adjust the path as needed

const BrandForm = () => {
    const [name, setName] = useState('');
    const [image, setImage] = useState(null);

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);

        for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`); // Log form data for debugging
        }

        try {
            const response = await axios.post(`${config.API_BASE_URL}/brands`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            alert('Brand added successfully!');
            console.log('Response:', response.data);
        } catch (error) {
            console.error('Error submitting form:', error.response.data);
        }
    };


    return (
        <form onSubmit={handleSubmit} encType="multipart/form-data">
             <div class="cn-contactform-input mb-25">
                    <label>Name</label>
                    <input type="text" name="name" placeholder="Brand Name" required />
             </div>
             <div class="cn-contactform-input mb-25">
                                       <label>Images</label>
                                       <input type="file" name="images" multiple required />
                                    </div>
            
            <div class="profile__main-logout text-sm-end">
                <button class="tp-logout-btn" type="submit">Submit</button>
            </div>
            {/*<div class="profile__main-logout text-sm-end">*/}
            {/*    <a href="login.html" class="tp-logout-btn" type= "submit">Logout</a>*/}
            {/*</div>*/}
        </form>



    );
};

export default BrandForm;
