import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../configuration/config'; // Adjust the path to your config

const ServicesSection = () => {
  const [servicesData, setServicesData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/services`);
        setServicesData(response.data);
      } catch (err) {
        setError('Failed to load services');
        console.error(err);
      }
    };

    fetchServices();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="sv-service-area project-panel-area-2">
      <div className="container-fluid p-0">
        {servicesData.map((service, index) => (
          <div key={`service-${index}`} className="sv-service-item project-panel-2">
            <div className="row g-0">
              <div className="col-xl-6 col-lg-6">
                <div className="sv-service-thumb">
                  <img
                    src={service.image ? `${config.API_BASE_URL_IMAGE}${service.image}` : 'fallback-image.jpg'}
                    alt={service.title || 'Service Image'}
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="sv-service-content-wrap d-flex align-items-center">
                  <div className="sv-service-content">
                    <div className="sv-service-title-box">
                      <span className="sv-service-subtitle">
                        <i>{index + 1}</i> {service.subtitle || 'Subtitle'}
                      </span>
                      <h4 className="sv-service-title">{service.title || 'Service Title'}</h4>
                    </div>
                    <div className="sv-service-space-wrap">
                      <div className="sv-service-text">
                        <p>{service.description || 'Service description goes here.'}</p>
                      </div>
                      <div className="sv-service-list">
                        <ul>
                          {(() => {
                            try {
                              const features = Array.isArray(service.features)
                                ? service.features
                                : JSON.parse(service.features);
                              return features.map((feature, idx) => <li key={idx}>{feature}</li>);
                            } catch {
                              return <li>No features available</li>;
                            }
                          })()}
                        </ul>
                      </div>
                      <div className="sv-service-btn">
                        <a
                          className="tp-btn-zikzak zikzak-inner p-relative"
                          href={service.link || '#'}
                        >
                          {/* <span className="zikzak-content">
                            See <br />
                            Details
                          </span> */}
                          {/* Add SVG animation as required */}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesSection;
