import React, { useState } from 'react';
import axios from 'axios';
import config from '../configuration/config'; // Adjust the path as needed

const ProjectForm = () => {
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [date, setDate] = useState('');
    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleImageChange = (e, setImage) => {
        setImage(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (!title || !category || !date || !image1 || !image2) {
            setError('All fields are required.');
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('category', category);
        formData.append('date', date);
        formData.append('image1', image1);
        formData.append('image2', image2);

        try {
            const response = await axios.post(`${config.API_BASE_URL}/projects`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            alert('Project added successfully!');
            setSuccess('Project added successfully.');
            setTitle('');
            setCategory('');
            setDate('');
            setImage1(null);
            setImage2(null);
        } catch (error) {
            setError('There was an error submitting the form.');
            console.error('Error submitting form:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="project-form">
            {error && <div className="error-message">{error}</div>}
            {success && <div className="success-message">{success}</div>}
            <div class="cn-contactform-input mb-25">
                <label>Title:</label>
                <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
            </div>
            <div class="cn-contactform-input mb-25">
                <label>Category:</label>
                <input type="text" value={category} onChange={(e) => setCategory(e.target.value)} required />
            </div>
            <div class="cn-contactform-input mb-25">
                <label>Date:</label>
                <input type="text" value={date} onChange={(e) => setDate(e.target.value)} required />
            </div>
            <div class="cn-contactform-input mb-25">
                <label>Image 1:</label>
                <input type="file" accept="image/*" onChange={(e) => handleImageChange(e, setImage1)} required />
            </div>
            <div class="cn-contactform-input mb-25">
                <label>Image 2:</label>
                <input type="file" accept="image/*" onChange={(e) => handleImageChange(e, setImage2)} required />
            </div>
            <div class="profile__main-logout text-sm-end">
                <button class="tp-logout-btn" type="submit">Submit</button>
            </div>
{/*            <button type="submit">Submit</button>*/}
        </form>
    );
};

export default ProjectForm;